import React, { useState } from "react";
import { Info, Title } from "../../../StyledComponents/StyledInfoTitle";
import ServiceSlots from "../../../components/ServiceSlote/ServiceSlote";
import OpeningHours from "../../../components/OpeningHours/OpeningHours";
import { Body } from "../../../StyledComponents/ScreenDiv";
import { useDispatch, useSelector } from "react-redux";
import { selectedService } from "../../../store/Slicers/mainSub";
import { RootState } from "../../../store/store";
import { SeeMoreButton } from "../../../StyledComponents/StyledServiceSlot";
import GoogleMapsButton from "../../../components/GoogleMapsButton/GoogleMapsButton";
import CallButton from "../../../components/CallButton/CallButton";

const BusinessInfoScreen: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.businessDataSlicer.userDoc);
  const services = useSelector((state: RootState) => state.businessDataSlicer.services);
  const [showAllServices, setShowAllServices] = useState<boolean>(false);

  const top4Services = showAllServices ? services : services.slice(0, 4);

  return (
    <Body>
      <Title>{data.businessName}</Title>
      <Info>
        <GoogleMapsButton address={data.address} />
        <CallButton phone={data.phoneNumber} />
      </Info>
      <section>
        <h3>About</h3>
        <p>{data.businessDescription}</p>
      </section>
      <section>
        <h3>Services</h3>
        {top4Services.map((service, index) => (
          <ServiceSlots
            key={index}
            callBack={() => dispatch(selectedService(service))}
            serviceData={service} // Pass the service data to the component
          />
        ))}
        {!showAllServices && services.length > 4 && (
          <SeeMoreButton onClick={() => setShowAllServices(true)}>See More</SeeMoreButton>
        )}
      </section>
      <OpeningHours />
      <div style={{ height: "100px" }}></div>
    </Body>
  );
};

export default BusinessInfoScreen;
