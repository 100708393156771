import { styled } from "styled-components";

export const NoMatchDivPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  gap: 20px;
  padding: 20px;
  color: white;
  background-color: #0d1b2a;
  img {
    max-width: 600px;
    transform: translateX(-8%);
    width: 50%;
  }
  h1 {
    font-size: 3rem;
    margin: 10px 0;
  }
  p {
    font-size: 1.5rem;
    margin: 10px 0;
  }
`;
