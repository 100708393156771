import React from "react";
import { InfoPressed } from "../../StyledComponents/StyledInfoTitle";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ICallButton {
  phone: string;
}
export const CallButton: React.FC<ICallButton> = (props) => {
  const openPhoneCall = () => {
    window.location.href = `tel:${props.phone}`;
  };

  return (
    <InfoPressed onClick={openPhoneCall}>
      <FontAwesomeIcon icon={faPhone} />
      {props.phone}
    </InfoPressed>
  );
};

export default CallButton;
