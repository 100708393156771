import React, { useState } from "react";
import { StyledFeatureText } from "../../../StyledComponents/StyeldeFetureSection";
import { Form, FormInstance, Input, Message, Schema, toaster } from "rsuite";
import {
  ContactUsButton,
  LoadingDiv,
  StyledContactUsCard,
  StyledContactUsSection,
} from "../../../StyledComponents/StyeldeContactUsSection";
import { ISectionId } from "../../../Types/interface/ISectionId/ISectionId";
import { InfinitySpin } from "react-loader-spinner";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../Firebase/firebase";

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}
const Textarea = React.forwardRef((props, ref) => (
  <Input
    {...props}
    as="textarea"
    ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
    rows={5}
    style={{ resize: "none" }}
  />
));

const ContactUs: React.FC<ISectionId> = ({ id }) => {
  const formRef = React.useRef<FormInstance>(null);
  const { StringType } = Schema.Types;
  const model = Schema.Model({
    name: StringType().isRequired("This field is required."),
    email: StringType()
      .isEmail("Please enter a valid email address.")
      .isRequired("This field is required."),
    message: StringType().isRequired("This field is required."),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<ContactFormData>({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (val: Record<string, any>) => {
    const newVal: ContactFormData = val as ContactFormData;
    setFormData({ ...newVal });
  };
  const handleSubmit = async () => {
    if (formRef.current?.check()) {
      await sendMessage();
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    }
  };

  const sendMessage = async () => {
    setLoading(true);
    try {
      const addWebsiteContactUs = httpsCallable(functions, "addWebsiteContactUs");
      const result = await addWebsiteContactUs(formData);
      const data: any = result.data;
      toaster.push(
        <Message showIcon type="success" closable>
          Meeting was added!
        </Message>,
        { duration: 5000 }
      );
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message showIcon type="error" closable>
          Meeting was not added, Please try Again.
        </Message>,
        { duration: 5000 }
      );
    }
    setLoading(false);
  };

  return (
    <StyledContactUsSection id={id}>
      <StyledContactUsCard>
        {loading ? (
          <LoadingDiv>
            <InfinitySpin width="200" color="#0E336F" />
          </LoadingDiv>
        ) : (
          <Form
            ref={formRef}
            model={model}
            formValue={formData}
            onChange={handleChange}
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Form.Group>
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Email</Form.ControlLabel>
              <Form.Control name="email" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>message</Form.ControlLabel>
              <Form.Control name="message" accepter={Textarea} />
            </Form.Group>
            <ContactUsButton type="submit">Submit</ContactUsButton>
          </Form>
        )}
      </StyledContactUsCard>
      <StyledFeatureText>
        <h4>Contact Us</h4>
        <p>
          Looking for more details? Have questions on your mind, or perhaps you have suggestions or
          concerns? Simply complete the form below, and a member of our service team will promptly
          respond, providing the answers you're seeking or addressing any feedback you may have.
        </p>
      </StyledFeatureText>
    </StyledContactUsSection>
  );
};

export default ContactUs;
