// StaticImage.js
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { BackGroundImg, LogoImg, StaticImageDiv } from "../../StyledComponents/StyledStaticImage";

const StaticImage: React.FC = () => {
  const data = useSelector((state: RootState) => state.businessDataSlicer.userDoc);
  return (
    <StaticImageDiv>
      <BackGroundImg src={data.backgroundURL} alt="Static Image" />
      {/* <BackGroundImg
        src={
          "https://images.unsplash.com/photo-1604355240616-5e907f42b431?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"
        }
        alt="Static Image"
      /> */}
      <LogoImg src={data.logoURL} alt="logo" />
    </StaticImageDiv>
  );
};

export default StaticImage;
