import { configureStore } from "@reduxjs/toolkit";
import mainSlicerReduce, { IMainSlicerState } from "./Slicers/mainSub";
import businessDataReduce, { IBusinessDataState } from "./Slicers/businessData";

export interface RootState {
  mainSlicer: IMainSlicerState;
  businessDataSlicer: IBusinessDataState;
}
export default configureStore({
  reducer: {
    mainSlicer: mainSlicerReduce,
    businessDataSlicer: businessDataReduce,
  },
});
