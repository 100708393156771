export const currencies: { code: string; locale: string; symbol: string }[] = [
  { code: "USD", locale: "en-US", symbol: "$" },
  { code: "EUR", locale: "en-IE", symbol: "€" },
  { code: "GBP", locale: "en-GB", symbol: "£" },
  { code: "JPY", locale: "ja-JP", symbol: "¥" },
  { code: "CAD", locale: "en-CA", symbol: "CA$" },
  { code: "AUD", locale: "en-AU", symbol: "AU$" },
  { code: "CHF", locale: "fr-CH", symbol: "CHF" },
  { code: "CNY", locale: "zh-CN", symbol: "¥" },
  { code: "INR", locale: "en-IN", symbol: "₹" },
  { code: "BRL", locale: "pt-BR", symbol: "R$" },
  { code: "MXN", locale: "es-MX", symbol: "MX$" },
  { code: "RUB", locale: "ru-RU", symbol: "₽" },
  { code: "ZAR", locale: "en-ZA", symbol: "R" },
  { code: "TRY", locale: "tr-TR", symbol: "₺" },
  { code: "SEK", locale: "sv-SE", symbol: "kr" },
  { code: "NOK", locale: "nb-NO", symbol: "kr" },
  { code: "SGD", locale: "en-SG", symbol: "S$" },
  { code: "NZD", locale: "en-NZ", symbol: "NZ$" },
  { code: "DKK", locale: "da-DK", symbol: "kr" },
  { code: "PLN", locale: "pl-PL", symbol: "zł" },
  { code: "THB", locale: "th-TH", symbol: "฿" },
  { code: "HKD", locale: "zh-HK", symbol: "HK$" },
  { code: "ILS", locale: "he-IL", symbol: "₪" },
  { code: "HUF", locale: "hu-HU", symbol: "Ft" },
  { code: "CZK", locale: "cs-CZ", symbol: "Kč" },
  { code: "IDR", locale: "id-ID", symbol: "Rp" },
  { code: "MYR", locale: "ms-MY", symbol: "RM" },
  { code: "PHP", locale: "en-PH", symbol: "₱" },
  { code: "SAR", locale: "ar-SA", symbol: "ر.س" },
  { code: "AED", locale: "ar-AE", symbol: "د.إ" },
  { code: "ARS", locale: "es-AR", symbol: "$" },
  { code: "CLP", locale: "es-CL", symbol: "$" },
  { code: "COP", locale: "es-CO", symbol: "$" },
  { code: "EGP", locale: "ar-EG", symbol: "ج.م" },
  { code: "PEN", locale: "es-PE", symbol: "S/" },
  { code: "RON", locale: "ro-RO", symbol: "lei" },
  { code: "KRW", locale: "ko-KR", symbol: "₩" },
  { code: "VND", locale: "vi-VN", symbol: "₫" },
  { code: "NGN", locale: "en-NG", symbol: "₦" },
  { code: "UAH", locale: "uk-UA", symbol: "₴" },
  { code: "KZT", locale: "kk-KZ", symbol: "₸" },
  { code: "QAR", locale: "ar-QA", symbol: "ر.ق" },
  { code: "KES", locale: "en-KE", symbol: "KSh" },
  { code: "KWD", locale: "ar-KW", symbol: "د.ك" },
  { code: "BDT", locale: "bn-BD", symbol: "৳" },
  { code: "MAD", locale: "ar-MA", symbol: "د.م." },
  { code: "LKR", locale: "si-LK", symbol: "₨" },
  { code: "HRK", locale: "hr-HR", symbol: "kn" },
];

export const numberFormat = (value: number, localeCurrency: string): string => {
  const currency = currencies.find((coin) => coin.locale === localeCurrency);
  return new Intl.NumberFormat(localeCurrency, {
    style: "currency",
    currency: currency?.code,
  }).format(value);
};
