import styled from "styled-components";
import { device } from "../Types/constData/Devices";

export const StaticImageDiv = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const BackGroundImg = styled.img`
  width: 100%;
  height: 100%;
  @media ${device.mobile} {
    height: 300px;
    object-fit: cover;
  }
`;
export const LogoImg = styled.img`
  position: absolute;
  height: 120px;
  width: 120px;
  top: 30px;
  right: -60px;
  border-radius: 15px;
  @media ${device.mobile} {
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    bottom: -60px;
  }
`;
