import { styled } from "styled-components";

export const DetailLine = styled.div`
  display: flex;
  max-width: 300px;
  justify-content: space-between;
  margin: 10px 30px;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
  font-weight: 700;
  h4 {
    font-size: 18px;
  }
  span {
    font-size: 14px;
  }
`;
