// App.js
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BusinessScreen from "./Screens/BusinessOrderingScreen/BusinessScreen";
import MainScreen from "./Screens/MoolaMainScreen/MainScreen/MainScreen";
import NoMatch from "./Screens/NoMatch/NoMatch";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainScreen />} />
          <Route path="/businessSite/:id" element={<BusinessScreen />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
