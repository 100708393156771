import styled from "styled-components";
import { device } from "../Types/constData/Devices";

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CalenderDiv = styled.div`
  width: 500px;
  align-self: center;
  color: #0e336e;
  @media ${device.mobile} {
    width: 100%;
  }
`;
