import React from "react";
import { StyledTitleSection } from "../../../StyledComponents/StyledTitleSection";
import { MainScreenButton } from "../../../StyledComponents/StyeldeFetureSection";
import { ISectionId } from "../../../Types/interface/ISectionId/ISectionId";
import { useNavigate } from "react-router-dom";

const TitleSection: React.FC<ISectionId> = ({ id }) => {
  const navigate = useNavigate();

  return (
    <StyledTitleSection id={id}>
      <div>
        <h1>
          Focus on work,
          <br /> We handle the rest.
        </h1>
        <p>
          Moola is designed to manage all your scheduling and appointment requirements, leaving you
          with uninterrupted time to focus completely on your tasks and work.
        </p>
        <MainScreenButton onClick={() => navigate("/businessSite/gedDsYq3FEViVrH9msaQ2LLa5E83")}>
          Explore now
        </MainScreenButton>
      </div>
      <img src="/images/iPhoneX.svg" alt="Mockup" />
    </StyledTitleSection>
  );
};

export default TitleSection;
