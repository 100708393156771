import { IMeeting } from "../../Types/interface/IMeeting/IMeeting";
import { IService } from "../../Types/interface/IService/IService";
import { createSlice } from "@reduxjs/toolkit";

export interface IMainSlicerState {
  isOpenOrder: boolean;
  counter: number;
  meeting: IMeeting;
}

export const MainSubSlice = createSlice({
  name: "MainSub",
  initialState: {
    isOpenOrder: false,
    counter: 0,
    meeting: {},
  } as IMainSlicerState,
  reducers: {
    onBookNow: (state) => {
      state.isOpenOrder = true;
    },
    selectedService: (state, data) => {
      const meetingData: IService = data.payload;
      state.meeting = {
        customerName: "",
        phoneNumber: "",
        serviceName: meetingData.serviceTitle,
        serviceDescription: meetingData.description,
        start: "",
        price: meetingData.price,
        durationInMinutes: meetingData.durationInMinuets,
        color: meetingData.color,
      };
      state.isOpenOrder = true;
      state.counter = 1;
    },
    selectedDate: (state, data) => {
      state.meeting.start = data.payload; // Use the serialized date string directly

      state.counter += 1;
    },
    addCustomer: (state, data) => {
      const customerData: any = data.payload;
      state.meeting.customerName = customerData.name;
      state.meeting.phoneNumber = customerData.phoneNumber;
      state.counter += 1;
    },
    goBack: (stats) => {
      stats.isOpenOrder = false;
      stats.meeting = {
        customerName: "",
        phoneNumber: "",
        serviceName: "",
        serviceDescription: "",
        start: "",
        price: 0,
        durationInMinutes: 0,
        color: 0,
      };
      stats.counter = 0;
    },
  },
});
export const { onBookNow, selectedService, goBack, selectedDate, addCustomer } =
  MainSubSlice.actions;

export default MainSubSlice.reducer;
