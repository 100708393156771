// App.js
import React from "react";
import { FeatureTitle, StyledFeatureSection } from "../../../StyledComponents/StyeldeFetureSection";
import FeatureCard from "../FeatureCard/FeatureCard";
import { ISectionId } from "../../../Types/interface/ISectionId/ISectionId";
const Feature: React.FC<ISectionId> = ({ id }) => {
  return (
    <StyledFeatureSection id={id}>
      <FeatureTitle>
        <h3>key Feature</h3>
        <p>
          Moola provides a variety of useful features, enhancing your experience and making running
          a business easier, here are some of them
        </p>
      </FeatureTitle>
      <FeatureCard
        title={"Have a Custom Business site"}
        paragraph={
          "Boost your business with a custom professional website that provides essential tailored information."
        }
        imgonleft={true}
        screen_img={"/images/screens/clientSite.svg"}
      />
      <FeatureCard
        title={"Hassle-free scheduling"}
        paragraph={
          "Say goodbye to the frustration of chasing people down. With Moola, appointments are automatically scheduled, and all you need to do is give your approval."
        }
        imgonleft={false}
        screen_img={"/images/iPhoneX.svg"}
      />

      <FeatureCard
        title={"Get insight on your business"}
        paragraph={
          "Get valuable insights to understand how your business is doing. Use this information to make improvements and grow your business."
        }
        imgonleft={true}
        screen_img={"/images/screens/insightsPage.svg"}
      />
    </StyledFeatureSection>
  );
};

export default Feature;
