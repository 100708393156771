import React from "react";
import { OpeningHoursDiv } from "../../StyledComponents/StyledOpeningHours";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const OpeningHours = () => {
  const data = useSelector((state: RootState) => state.businessDataSlicer.userDoc);
  return (
    <section>
      <h3>Opening Hours</h3>
      {data.OpeningHours.map((day, index) => (
        <OpeningHoursDiv key={index}>
          <span>{day.dayOfWeek}</span>
          {day.isActive ? (
            <span>
              {day.startTime} - {day.endTime}
            </span>
          ) : (
            <span>Close</span>
          )}
        </OpeningHoursDiv>
      ))}
    </section>
  );
};

export default OpeningHours;
