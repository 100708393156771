import { styled } from "styled-components";
import { device } from "../Types/constData/Devices";

export const StyledTitleSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;
  background-color: #3c42d7;
  color: white;
  gap: 20vw;
  div {
    display: flex;
    flex-direction: column;
    width: 450px;
    gap: 10px;
  }
  h1 {
    font-size: 55px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    text-align: justify;
  }
  img {
    height: 520px;
    /* animation: growAndShrink 10s infinite;  */
  }

  @keyframes growAndShrink {
    0%,
    100% {
      transform: scale(1); /* Original size */
    }
    50% {
      transform: scale(1.05); /* Larger size */
    }
  }

  @media ${device.mobile} {
    flex-direction: column;
    gap: 10px;
    height: auto;
    padding: 50px 0px;

    div {
      width: auto;
      margin: 5vw;
    }
    h1 {
      font-size: 3em;
      line-height: 1em;
      font-weight: 700;
    }
    img {
      height: 300px;
    }
  }
`;
