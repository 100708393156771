import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase/firebase";
import { useParams } from "react-router-dom";
import { addBusinessData } from "../../store/Slicers/businessData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import StaticImage from "../../components/staticImge/staticImeg";
import ScrollableContent from "../../components/scrollableContent/scrollableContent";
import StickyButton from "../../components/StickyButtom/StickyButtom";

const BusinessScreen: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [gotData, setGotData] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const isOpenOrder = useSelector((state: RootState) => state.mainSlicer.isOpenOrder);
  useEffect(() => {
    const setVisitCount = async () => {
      const siteVisit = httpsCallable(functions, "siteVisit");
      await siteVisit({ id: id });
    };
    const inSession = sessionStorage.getItem("inSession");
    if (!inSession) {
      sessionStorage.setItem("inSession", "true");
      setVisitCount();
    }
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const websiteData = httpsCallable(functions, "websiteData");
        const result = await websiteData({ id: id });
        const data: any = result.data;

        dispatch(addBusinessData(data.data));
      } catch (error: any) {
        console.log(error);
        setGotData(false);
      }
      setLoading(false); // Make sure to stop loading even if an error occurs
    };

    getData();
  }, [dispatch, id]);

  return (
    <>
      <div className="app">
        {loading || !gotData ? (
          <LoadingScreen connectionError={gotData} />
        ) : (
          <>
            <StaticImage />
            <ScrollableContent />
            {!isOpenOrder && <StickyButton />}
          </>
        )}
      </div>
    </>
  );
};

export default BusinessScreen;
