import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { Spin as Hamburger } from "hamburger-react";

const Header: React.FC = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleNavLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href");
    if (targetId) {
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    setDropdownVisible(false); // Close the dropdown after clicking a link
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <header>
      <div className="logoLine">
        <img src="/logos/appLogo2.png" style={{ height: "30px" }} alt="logo" />
        <img src="/logos/logoText.svg" style={{ height: "15px" }} alt="logo" />
      </div>
      <div className="navigation">
        <a href="#title" onClick={handleNavLinkClick}>
          About
        </a>
        <a href="#feature" onClick={handleNavLinkClick}>
          Feature
        </a>
        <a href="#download" onClick={handleNavLinkClick}>
          Download
        </a>
        <a href="#contact" onClick={handleNavLinkClick}>
          Contact Us
        </a>
      </div>
      <div className="dropdown" ref={dropdownRef}>
        <Hamburger rounded toggled={isDropdownVisible} toggle={setDropdownVisible} />
        {isDropdownVisible && (
          <div className="dropdown-menu">
            <a href="#title" onClick={handleNavLinkClick}>
              About
            </a>
            <a href="#feature" onClick={handleNavLinkClick}>
              Feature
            </a>
            <a href="#download" onClick={handleNavLinkClick}>
              Download
            </a>
            <a href="#contact" onClick={handleNavLinkClick}>
              Contact Us
            </a>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
