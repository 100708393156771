import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { DetailLine } from "../../../StyledComponents/StyledDetailLine";
import { StyledSubmitButton } from "../../../StyledComponents/StyledStickyButton";
import { numberFormat } from "../../../Types/constData/Currencies";
import { InfinitySpin } from "react-loader-spinner";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../Firebase/firebase";
import { goBack } from "../../../store/Slicers/mainSub";
import { useParams } from "react-router-dom";
import { Message, useToaster } from "rsuite";
const ConfirmScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const meeting = useSelector((state: RootState) => state.mainSlicer.meeting);
  const [loading, setLoading] = useState<boolean>(false);
  const toaster = useToaster();
  const [datePart, setDatePart] = useState<string>("");
  const [timePart, setTimePart] = useState<string>("");
  const userDef = useSelector((state: RootState) => state.businessDataSlicer.userDoc);

  useEffect(() => {
    const [dateComponent, timeComponent] = meeting.start.split("T");
    const date = new Date(dateComponent);
    setDatePart(
      date.toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
      })
    );
    setTimePart(timeComponent.slice(0, 5));
  }, []);

  // Handle form submission
  const onSubmit = async () => {
    setLoading(true);
    try {
      const timeOffset = new Date().getTimezoneOffset();
      const addWebsiteMeeting = httpsCallable(functions, "addWebsiteMeeting");
      const result = await addWebsiteMeeting({
        meeting: meeting,
        userId: id,
        offSetTimeZone: timeOffset,
      });
      const data: any = result.data;
      toaster.push(
        <Message showIcon type="success" closable>
          Meeting was added!
        </Message>,
        { duration: 5000 }
      );
      dispatch(goBack());
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message showIcon type="error" closable>
          Meeting was not added, Please try Again.
        </Message>,
        { duration: 5000 }
      );
    }
    setLoading(false);
  };

  const detailLine = (title: string, detail: string) => {
    return (
      <DetailLine>
        <h4>{title}:</h4>
        <span>{detail}</span>
      </DetailLine>
    );
  };

  return (
    <>
      {loading ? (
        <div style={{ alignSelf: "center", margin: "15vh" }}>
          <InfinitySpin width="200" color="#0E336F" />
        </div>
      ) : (
        <>
          <section>
            <h3>Meeting Detail</h3>
            {detailLine("Service", meeting.serviceName)}
            {detailLine("Customer Name", meeting.customerName)}
            {detailLine("Date", datePart)}
            {detailLine("Hour", timePart)}
            {detailLine("price", numberFormat(meeting.price, userDef.localeCurrency))}
          </section>
          <StyledSubmitButton disabled={false} on={true} onClick={onSubmit}>
            Confirm Meeting
          </StyledSubmitButton>
        </>
      )}
    </>
  );
};

export default ConfirmScreen;
