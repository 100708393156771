import { styled } from "styled-components";
import { device } from "../Types/constData/Devices";

export const StyledGrideTimeSlots = styled.div`
  display: grid;
  grid-gap: 10px;
  align-self: center;
  max-width: 500px;
  grid-template-columns: repeat(auto-fill, 75px);
  margin-bottom: 100px;
  div {
    cursor: pointer;
  }
  div:hover {
    background-color: #f5f5f5;
    scale: 1.05;
  }
  @media (${device.mobile}) {
    /* margin: auto; */
    align-self: center;
    width: 90vw;
  }
`;
export const GrideBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #000;
`;
