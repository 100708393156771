import styled from "styled-components";
import { device } from "../Types/constData/Devices";

export const StyledFooter = styled.footer`
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 40px 15%;
  height: fit-content;
  div {
    margin: 40px 20px;
  }
  a {
    white-space: nowrap;
    color: gray;
    text-decoration: none;
  }
`;
