import React from "react";
import { Slot } from "../../StyledComponents/StyledServiceSlot";
import { IServiceSlots } from "../../Types/interface/IServiceSlots/IServiceSlots";
import { IService } from "../../Types/interface/IService/IService";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { numberFormat } from "../../Types/constData/Currencies";

const ServiceSlots: React.FC<IServiceSlots> = (props) => {
  const service: IService = props.serviceData;
  const localeCurrency = useSelector(
    (state: RootState) => state.businessDataSlicer.userDoc.localeCurrency
  );

  return (
    <Slot onClick={() => props.callBack()}>
      <div>
        <h4>{service.serviceTitle}</h4>
        <span>{service.durationInMinuets} minute</span>
      </div>
      <h4>{numberFormat(service.price, localeCurrency)}</h4>
      <p>+</p>
    </Slot>
  );
};

export default ServiceSlots;
