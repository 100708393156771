import { IMeeting } from "../../Types/interface/IMeeting/IMeeting";
import { IService } from "../../Types/interface/IService/IService";
import { createSlice } from "@reduxjs/toolkit";
import { IUserDoc } from "../../Types/interface/IUserData/IUserData";
export interface IBusinessDataState {
  userDoc: IUserDoc;
  services: IService[];
}

export const BusinessData = createSlice({
  name: "BusinessData",
  initialState: {
    userDoc: {},
    services: [{}],
  } as IBusinessDataState,
  reducers: {
    addBusinessData: (state, data) => {
      const _data = data.payload as IBusinessDataState;
      state.userDoc = _data.userDoc;
      state.services = _data.services;
    },
  },
});
export const { addBusinessData } = BusinessData.actions;

export default BusinessData.reducer;
