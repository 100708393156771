import styled from "styled-components";
import { device } from "../Types/constData/Devices";

export const Title = styled.h1`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  margin-top: 50px;
  margin-bottom: 0;
  font-weight: 700;
  @media ${device.mobile} {
    margin: auto;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 50px;
  font-weight: 400;
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #0e336e;
  @media ${device.mobile} {
    font-size: 18px;
    flex-direction: column;
    gap: 20px;
  }
`;

export const NoMeetingMessage = styled(Title)`
  align-self: center;
  text-align: justify;
  font-size: 20px;
  margin: 40px;
`;

export const InfoPressed = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  transition: 0.2s;
  &:hover {
    scale: 1.05;
  }
`;
