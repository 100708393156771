// App.js
import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/MainScreenComponents/Header/Header";
import TitleSection from "../../../components/MainScreenComponents/TitleSection/TitleSection";
import Feature from "../../../components/MainScreenComponents/Feature/Feature";
import DownloadSection from "../../../components/MainScreenComponents/DownloadSection/DownloadSection";
import ContactUs from "../../../components/MainScreenComponents/ContactUs/ContactUs";
import Footer from "../../../components/MainScreenComponents/Footer/Footer";
const MainScreen: React.FC = () => {
  return (
    <>
      <Header />
      <TitleSection id="title" />
      <Feature id="feature" />
      <DownloadSection id="download" />
      <ContactUs id="contact" />
      <Footer />
    </>
  );
};

export default MainScreen;
