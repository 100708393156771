// App.js
import React from "react";
import { ImageDiv, StyledDownloadSection } from "../../../StyledComponents/StyledDownloadSection";
import { ISectionId } from "../../../Types/interface/ISectionId/ISectionId";
const DownloadSection: React.FC<ISectionId> = ({ id }) => {
  return (
    <StyledDownloadSection id={id}>
      <h1>Coming soon Moola App</h1>
      <p>
        Discover the vibrant Moola community, where business growth feels effortless. Get ready to
        transform your business with Moola – launching soon on all platforms!
      </p>
      <ImageDiv>
        <img src="/images/app_store_button.svg" alt="App Store" />
        <img src="/images/google_play_button.svg" alt="Google Play" />
      </ImageDiv>
    </StyledDownloadSection>
  );
};

export default DownloadSection;
