import { styled } from "styled-components";
import { device } from "../Types/constData/Devices";
interface IFeatureLine {
  $imgOnLeft: boolean;
}

export const StyledFeatureSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 75px;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 120px;
  max-width: 1100px;
  h3 {
    font-size: 36px;
    font-weight: 600;
  }
  p {
    text-align: center;
  }
`;

export const FeatureTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  h3 {
    font-size: 36px;
    font-weight: 600;
    color: black;
  }
  p {
    font-size: 16px;
    text-align: center;
    margin: 0px 280px;
  }
  @media ${device.mobile} {
    p {
      margin: 0px 40px;
    }
  }
`;

export const StyledFeatureText = styled.div`
  gap: 30px;
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: baseline;
  padding: 40px 20px;
  h4 {
    color: black;
    font-size: 36px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    text-align: start;
  }

  @media ${device.mobile} {
    width: 100%;
    padding: 20px 30px;
  }
`;
export const FeatureLine = styled.div<IFeatureLine>`
  display: flex;
  flex-direction: ${(props) => (props.$imgOnLeft ? "row-reverse" : "row")};
  align-items: start;
  gap: 10vw;
  margin-bottom: 50px;
  img {
    height: 470px;
    transform: ${(props) => (props.$imgOnLeft ? "rotate(-10deg)" : "rotate(10deg)")};
    transition: 200ms;
  }
  img:hover {
    scale: 1.05;
  }
  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    img {
      height: 300px;
      transform: rotate(0deg);
    }
  }
`;

export const MainScreenButton = styled.button`
  border-radius: 5px;
  background: #fff;
  color: #222;
  font-size: 16px;
  font-weight: 600;
  width: fit-content;
  margin-top: 20px;
  padding: 15px 40px;
  transition: 0.1s;
  &:hover {
    background: #d3d3d3;
    scale: 1.04;
  }
`;
