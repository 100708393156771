import { styled } from "styled-components";
import { device } from "../Types/constData/Devices";
import { StyledGrideTimeSlots } from "./StyeldGridTimeSlots";
import { FeatureTitle } from "./StyeldeFetureSection";
import { StyledButton } from "./StyledStickyButton";

export const StyledContactUsSection = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 15%;
  align-items: center;
  /* background: linear-gradient(163deg, #ebebeb 0%, #b7b7b7 100%); */
  padding: 50px 100px;
  @media ${device.mobile} {
    flex-direction: column-reverse;
    gap: 50px;
    padding: 50px 20px;
  }
`;

export const StyledContactUsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #f8f8f8;
  width: fit-content;
  padding: 40px 20px;
  width: fit-content;
  box-shadow: -7px 11px 20px 3px rgba(0, 0, 0, 0.18);
  @media ${device.mobile} {
    padding: 20px 8px;
  }
`;
export const ContactUsTitle = styled(FeatureTitle)`
  p {
    margin: 0;
  }
`;

export const ContactUsButton = styled(StyledButton)`
  width: 100%;
`;

export const LoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 370px;
  width: 300px;
`;
