import React from "react";
import "rsuite/dist/rsuite.min.css";
import { useDispatch, useSelector } from "react-redux";
import { selectedService } from "../../../store/Slicers/mainSub";
import { RootState } from "../../../store/store";
import ServiceSlots from "../../../components/ServiceSlote/ServiceSlote";

const ServiceSelectionScreen: React.FC = () => {
  const dispatch = useDispatch();
  const services = useSelector((state: RootState) => state.businessDataSlicer.services);

  return (
    <>
      <h3>Services</h3>
      {services.map((service, index) => (
        <ServiceSlots
          key={index}
          callBack={() => dispatch(selectedService(service))}
          serviceData={service} // Pass the service data to the component
        />
      ))}
    </>
  );
};

export default ServiceSelectionScreen;
