// App.js
import React from "react";
import { StyledFeatureText, FeatureLine } from "../../../StyledComponents/StyeldeFetureSection";
interface IFeatureCard {
  title: string;
  paragraph: string;
  screen_img: string;
  imgonleft: boolean;
}

const FeatureCard: React.FC<IFeatureCard> = (props) => {
  return (
    <FeatureLine $imgOnLeft={props.imgonleft}>
      <StyledFeatureText>
        <h4>{props.title}</h4>
        <p>{props.paragraph}</p>
      </StyledFeatureText>
      <img src={props.screen_img} alt="screen" />
    </FeatureLine>
  );
};

export default FeatureCard;
