import React, { useEffect, useState } from "react";
import "rsuite/dist/rsuite.min.css";
import { useDispatch } from "react-redux";
import { Checkbox, Form } from "rsuite";
import "react-phone-number-input/style.css";
import { PhoneInput, usePhoneValidation } from "react-international-phone";
import "react-international-phone/style.css";
import { StyledSubmitButton } from "../../../StyledComponents/StyledStickyButton";
import { addCustomer } from "../../../store/Slicers/mainSub";
const CustomerScreen: React.FC = () => {
  const dispatch = useDispatch();

  const [submitOn, setSubmitOn] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    saveData: false,
  });
  const phoneValidation = usePhoneValidation(formData.phoneNumber);
  // Handle input changes
  const handleChange = (field: string, value: any) => {
    setSubmitOn(false);
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (formData.name.trim().length !== 0 && phoneValidation.isValid) {
      setSubmitOn(true);
    }
  }, [formData]);
  useEffect(() => {
    const localStorageData = localStorage.getItem("formData");
    if (localStorageData) {
      const localFormData = JSON.parse(localStorageData);
      setFormData({
        name: localFormData.name,
        phoneNumber: localFormData.phoneNumber,
        saveData: localFormData.saveData,
      });
    }
  }, []);
  // Handle form submission
  const onSubmit = () => {
    if (formData.saveData) {
      localStorage.setItem("formData", JSON.stringify(formData));
    } else {
      localStorage.removeItem("formData");
    }
    dispatch(addCustomer(formData));
  };
  return (
    <>
      <section>
        <h3>Personal Details</h3>
        <Form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column" }}>
          <Form.Group>
            <Form.ControlLabel>Full Name</Form.ControlLabel>
            <Form.Control
              name="name"
              value={formData.name}
              onChange={(value) => handleChange("name", value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Phone Number</Form.ControlLabel>

            <PhoneInput
              defaultCountry="il"
              value={formData.phoneNumber}
              onChange={(phone) => handleChange("phoneNumber", phone)}
            />
          </Form.Group>
          <Checkbox
            checked={formData.saveData}
            onChange={() => handleChange("saveData", !formData.saveData)}>
            Save Definition
          </Checkbox>
          <StyledSubmitButton disabled={!submitOn} on={submitOn} type="submit">
            Submit
          </StyledSubmitButton>
        </Form>
      </section>
    </>
  );
};

export default CustomerScreen;
