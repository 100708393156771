import { styled } from "styled-components";
import { device } from "../Types/constData/Devices";

export const StyledDownloadSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: fit-content;
  padding: 120px 0px;
  background: linear-gradient(0deg, #3c42d7 0%, #2d34c1 100%);
  color: white;
  gap: 20px;
  h1 {
    font-size: 36px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    text-align: center;
  }
  @media ${device.mobile} {
    padding: 50px 10px;

    h1 {
      font-size: 3em;
      line-height: 1em;
      font-weight: 700;
    }
  }
`;

export const ImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  img {
    cursor: pointer;
  }
  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    img {
      width: 70%;
    }
  }
`;
