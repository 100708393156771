// ScrollableContent.js
import React from "react";
import "./scrollableContent.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import OrderWrapperScreen from "../../Screens/BusinessOrderingScreen/OrderWrapperScreen/OrderWrapperScreen";
import BusinessInfoScreen from "../../Screens/BusinessOrderingScreen/BusinessInfoScreen/BusinessInfoScreen";
import { LogoImag } from "../../StyledComponents/StyledStickyButton";
import { useNavigate } from "react-router-dom";
const ScrollableContent: React.FC = () => {
  const isOpenOrder = useSelector((state: RootState) => state.mainSlicer.isOpenOrder);
  const navigate = useNavigate(); // Get the navigate function from the hook

  return (
    <div className="scrollable-content">
      {!isOpenOrder ? <BusinessInfoScreen /> : <OrderWrapperScreen />}
      <LogoImag onClick={() => navigate("/")}>
        <span>Run By</span>
        <img src="/logos/logoText.svg" alt="Moola" />
      </LogoImag>
    </div>
  );
};

export default ScrollableContent;
