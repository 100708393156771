import React from "react";
import { LoadingDiv } from "../../StyledComponents/StyledLoding";
import { InfinitySpin } from "react-loader-spinner";
interface ILoadingScreen {
  connectionError: boolean;
}
const LoadingScreen: React.FC<ILoadingScreen> = (props) => {
  return (
    <LoadingDiv>
      <img src="/logos/logoAndText.svg" alt="Logo" />
      {!props.connectionError ? (
        <h4>Network error. Please retry.</h4>
      ) : (
        <InfinitySpin width="200" color="#0E336F" />
      )}
    </LoadingDiv>
  );
};

export default LoadingScreen;
