import React, { useEffect, useState } from "react";
import {
  LogoImag,
  OpenClose,
  StickyDiv,
  StyledButton,
} from "../../StyledComponents/StyledStickyButton";
import { useDispatch, useSelector } from "react-redux";
import { onBookNow } from "../../store/Slicers/mainSub";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";

const StickyButton = () => {
  const dispatch = useDispatch();
  const OpeningHours = useSelector(
    (state: RootState) => state.businessDataSlicer.userDoc.OpeningHours
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const todayIndex = new Date().getDay();
  const currentDay = new Date().toLocaleDateString("en-US", { weekday: "long" });
  const currentTime = new Date().toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const todayDefinition = OpeningHours.find((day) => day.dayOfWeek === currentDay);

  const isBusinessOpen = (): boolean => {
    //if day not exists return false
    if (!todayDefinition) {
      return false;
    }
    if (!todayDefinition.isActive) {
      return false;
    }
    const startTime = new Date(`1970-01-01T${todayDefinition.startTime}`);
    const endTime = new Date(`1970-01-01T${todayDefinition.endTime}`);
    const currentTimeObj = new Date(`1970-01-01T${currentTime}`);
    return currentTimeObj >= startTime && currentTimeObj <= endTime;
  };

  const ifCloseWhenOpen = (): string => {
    let dayIndex = (todayIndex + 1) % 7;
    let openWhenTime = OpeningHours[dayIndex].startTime;
    let openWhenDay = "";
    for (let index = 0; index < 7; index++) {
      if (OpeningHours[dayIndex].isActive) {
        break;
      }
      dayIndex = (dayIndex + 1) % 7;
      openWhenTime = OpeningHours[dayIndex].startTime;
      openWhenDay = OpeningHours[dayIndex].dayOfWeek.substring(0, 3);
    }

    return openWhenTime + " " + openWhenDay;
  };

  useEffect(() => {
    setIsOpen(isBusinessOpen());
  }, [OpeningHours]);

  return (
    <StickyDiv>
      <p>
        <OpenClose $openForBusiness={isOpen}>{isOpen ? "Open" : "Close"} </OpenClose> until{" "}
        {isOpen ? OpeningHours[todayIndex].endTime : ifCloseWhenOpen()}
        {}
      </p>
      <StyledButton onClick={() => dispatch(onBookNow())}>Book now</StyledButton>
    </StickyDiv>
  );
};

export default StickyButton;
