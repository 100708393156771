import React from "react";
import { InfoPressed } from "../../StyledComponents/StyledInfoTitle";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface IGoogleMapsButton {
  address: string;
}
export const GoogleMapsButton: React.FC<IGoogleMapsButton> = (props) => {
  const openGoogleMaps = () => {
    const encodedAddress = encodeURIComponent(props.address);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <InfoPressed onClick={openGoogleMaps}>
      {" "}
      <FontAwesomeIcon icon={faLocationArrow} className="location-icon" />
      {props.address}
    </InfoPressed>
  );
};

export default GoogleMapsButton;
