import styled from "styled-components";
import { device } from "../Types/constData/Devices";

export const StickyDiv = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  background-color: white;
  flex-direction: row;
  justify-content: space-around;
  bottom: 0;
  height: 50px;
  align-items: center;
  border-radius: 15px 15px 0 0;
  span {
    font-size: 18px;
  }
  @media ${device.mobile} {
    position: sticky;
    padding: 10px;
  }
`;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #2f3fd1;
  box-shadow: 0px 14px 23px 0px rgba(28, 37, 44, 0.08);
  font-size: 18px;
  font-weight: 700;
  width: 400px;
  height: 40px;
  letter-spacing: 1.5px;
  color: white;
  border: none;

  &:hover {
    background: #2735ad;
  }
  @media ${device.mobile} {
    width: auto;
    padding: 0px 35px;
  }
`;
interface IOpenClose {
  $openForBusiness: boolean;
}
export const OpenClose = styled.span<IOpenClose>`
  color: ${(props) => (props.$openForBusiness ? "green" : "red")};
  font-weight: 600;
`;
interface IStyledSubmitButton {
  on: boolean;
}
export const StyledSubmitButton = styled(StyledButton)<IStyledSubmitButton>`
  align-self: center;
  width: 250px;
  transform: translateY(70px);
  background-color: ${(props) => (props.on ? "#2f3fd1" : "#7b7d84")};
  &:hover {
    background-color: ${(props) => (props.on ? "#2735ad" : "#7b7d84")};
  }
  @media (max-width: 920px) {
    width: auto;
    padding: 0px 15px;
  }
`;

export const LogoImag = styled.div`
  cursor: pointer;
  display: flex;
  align-self: center;
  align-items: baseline;
  flex-direction: row;
  margin-bottom: 15px;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
  transition: opacity 1s, transform 1s;
  img {
    width: 90px;
    /* Apply the transition properties to the img element */
  }
  &:hover {
    opacity: 0.7;
    transform: scale(1.05);
  }
`;
