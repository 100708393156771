import React from "react";
import { StyledFooter } from "../../../StyledComponents/StyledFooter";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const link: string = "http://moolaapp.co.il/";
  const { t } = useTranslation(); // Initialize the useTranslation hook
  return (
    <StyledFooter>
      <a href={link}>{t("footer.link1")}</a>
      <a href={link}>{t("footer.link2")}</a>
      <a href={link}>{t("footer.link3")}</a>
      <a href={link}>{t("footer.link4")}</a>
      <a href={link}>{t("footer.link5")}</a>
      <a href={link}>{t("footer.link6")}</a>
      <a href={link}>{t("footer.link7")}</a>
      <a href={link}>{t("footer.link8")}</a>
      <a href={link}>{t("footer.link9")}</a>
      <a href={link}>{t("footer.link10")}</a>
      <a href={link}>{t("footer.link11")}</a>
      <a href={link}>{t("footer.link12")}</a>
      <a href={link}>{t("footer.link13")}</a>
      <a href={link}>{t("footer.link14")}</a>
      <a href={link}>{t("footer.link15")}</a>
      <a href={link}>{t("footer.link16")}</a>
      <a href={link}>{t("footer.link17")}</a>
    </StyledFooter>
  );
};

export default Footer;
