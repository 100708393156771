// ScrollableContent.js
import React from "react";
import { Steps } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useDispatch, useSelector } from "react-redux";
import ServiceSelectionScreen from "../ServiceSelectionScreen/ServiceSelectionScreen";
import DateTimeSelectionScreen from "../DateTimeSelectionScreen/DateTimeSelectionScreen";
import { RootState } from "../../../store/store";
import { Body } from "../../../StyledComponents/ScreenDiv";
import { goBack } from "../../../store/Slicers/mainSub";
import { Info } from "../../../StyledComponents/StyledInfoTitle";
import CustomerScreen from "../CustomerScreen/CustomerScreen";
import ConfirmScreen from "../ConfirmScreen/ConfirmScreen";
const OrderWrapperScreen: React.FC = (props) => {
  const wrapperOrderScreens = [
    <ServiceSelectionScreen />,
    <DateTimeSelectionScreen />,
    <CustomerScreen />,
    <ConfirmScreen />,
  ];
  const counter = useSelector((state: RootState) => state.mainSlicer.counter);
  const dispatch = useDispatch();

  return (
    <Body>
      <Steps current={counter} className="steps">
        <Steps.Item />
        <Steps.Item />
        <Steps.Item />
        <Steps.Item />
      </Steps>
      {wrapperOrderScreens[counter]}
      <Info>
        <div
          style={{ marginTop: "100px", cursor: "pointer" }}
          onClick={() => {
            dispatch(goBack());
          }}>
          Return to Main Page
        </div>
      </Info>
    </Body>
  );
};

export default OrderWrapperScreen;
