// App.js
import React from "react";
import { StyledButton } from "../../StyledComponents/StyledStickyButton";
import { useNavigate } from "react-router-dom";
import AnimatedJSONToGIF from "../../components/AnimatedJSONToGIF/AnimatedJSONToGIF";
import { NoMatchDivPage } from "../../StyledComponents/StyledNoMatch";

const NoMatch: React.FC = () => {
  const navigate = useNavigate();
  return (
    <NoMatchDivPage>
      <img src="/404/404.svg" alt="404" />
      <h1>Oops! Page Not Found</h1>
      <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
      <StyledButton onClick={() => navigate("/")} formTarget="_self">
        Go Back to Home
      </StyledButton>
    </NoMatchDivPage>
  );
};

export default NoMatch;
