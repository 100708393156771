import React, { useEffect, useState } from "react";
import "rsuite/dist/rsuite.min.css";
import { Calendar } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { selectedDate } from "../../../store/Slicers/mainSub";
import { CalenderDiv } from "../../../StyledComponents/ScreenDiv";
import { httpsCallable } from "firebase/functions";
import { InfinitySpin } from "react-loader-spinner";
import { functions } from "../../../Firebase/firebase";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store/store";
import { NoMeetingMessage } from "../../../StyledComponents/StyledInfoTitle";
import { GrideBlock, StyledGrideTimeSlots } from "../../../StyledComponents/StyeldGridTimeSlots";

const DateTimeSelectionScreen: React.FC = () => {
  const dispatch = useDispatch();
  const DayDiffract = new Date().getTimezoneOffset();
  const { id } = useParams<{ id: string }>();
  const meetingData = useSelector((state: RootState) => state.mainSlicer.meeting);
  const OpeningHours = useSelector(
    (state: RootState) => state.businessDataSlicer.userDoc.OpeningHours
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [selected, setSelectedDate] = useState<Date>(new Date());
  const [timeSlots, settimeSlots] = useState<string[]>([]);

  const onChangeDate = (date: Date) => setSelectedDate(date);

  const onSelectTime = (time: string) => {
    const splitTime = time.split(":");
    const dateTime = selected;
    dateTime.setHours(parseInt(splitTime[0]));
    dateTime.setMinutes(parseInt(splitTime[1]) - DayDiffract);
    dateTime.setSeconds(0, 0);
    dispatch(selectedDate(dateTime.toISOString())); // Convert Date to a string using toISOString()
  };
  const todayOpeningHour = (selected: Date) => {
    const selectedDay = selected.toLocaleDateString("en-US", { weekday: "long" });
    return OpeningHours.find((day) => day.dayOfWeek === selectedDay);
  };

  useEffect(() => {
    setLoading(true);
    const adjustDate = new Date(selected);
    adjustDate.setMinutes(adjustDate.getMinutes() - DayDiffract);
    const getTimeSlots = async () => {
      try {
        const today = new Date();
        const timeSlotsGenerate = httpsCallable(functions, "timeSlotsGenerate");
        const result = await timeSlotsGenerate({
          userId: id,
          date: adjustDate.toISOString(),
          serviceDuration: meetingData.durationInMinutes,
          openHours: todayOpeningHour(selected),
          offSetTimeZone: today.getTimezoneOffset(),
        });
        const data: any = result.data;
        settimeSlots(data.timeSlotList);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getTimeSlots();
  }, [selected, id, meetingData.durationInMinutes, OpeningHours]);

  return (
    <>
      <section>
        <h3>Select Date and Time</h3>
        <CalenderDiv>
          <Calendar compact bordered onChange={onChangeDate} />
        </CalenderDiv>
      </section>
      {loading ? (
        <div style={{ alignSelf: "center" }}>
          <InfinitySpin width="200" color="#0E336F" />
        </div>
      ) : timeSlots.length >= 1 ? (
        <StyledGrideTimeSlots>
          {timeSlots.map((time, index) => (
            <GrideBlock key={index} onClick={() => onSelectTime(time)}>
              {time}
            </GrideBlock>
          ))}
        </StyledGrideTimeSlots>
      ) : (
        <NoMeetingMessage>Oops! No open slots on this date. Please try another.</NoMeetingMessage>
      )}
    </>
  );
};

export default DateTimeSelectionScreen;
